.sectionCol {
    text-align: center;
}

.sectionTitle {
    color: #383737;
    font-family: Montserrat, sans-serif;
    font-size: min(8vw, 64px);
    font-weight: 400;
    margin-top: 10vh;
    margin-bottom: 5vh;
}

.accordion {
    text-align: left;
    margin-bottom: 5%;
}

.accordionButtonHeader *{
    color: #383737 !important;
    font-family: Montserrat, sans-serif;
    font-size: 32px;
    font-weight: 700;
}

.textTitle {
    color: #767676;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 700;
}

.textDescription {
    color: #767676;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 400;
}