.table {
}

.tableBody:hover>tr:hover>* {
    color: #ffffff;
    --bs-table-hover-bg: rgb(168, 244, 221, 0.075);
}

.columnHeaderText {
    color: #ffffff;
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
}

.tableHeaderRow, .tableRow {
    border: 1px solid #a8f4dd;
    flex-direction: row;
}

.eventText {
    color: #ffffff;
    font-family: Montserrat, sans-serif;
    font-size: max(1.5vw, 14px);
    font-weight: 500;
    vertical-align: middle;
}
.eventText * {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 !important;
}

.startCol, .centerCol, .endCol {
    text-align: center;
}

.centerCol, .endCol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.baywatchEmojis {
    font-size: 20px;
}

@media only screen and (max-width: 767px) {
    .baywatchEmojis1 {
        display: none;
    }
    .baywatchEmojis2 {
        display: block;
    }

    .eventText.startCol {
        font-size: 12px;
    }

    .centerCol {
        width: 90%;
    }

    .endCol {
        /* width: 125%; */
        min-width: 80px;
        text-align: left;
    }
}