/* Container containg section text and button */
.sectionContainer {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    display: flex;
    align-items: center;
}

.textRow {
    height: 100%;
    width: 100%;
    align-items: center;
    z-index: 1;
}

.textCol {
    text-align: center;
}

/* Large text in section */
.headerText {
    color: #ffffff;
    font-family: Montserrat, sans-serif;
    font-size: min(10vw, 64px);
    font-weight: 700;
}

/* Small text in section */
.descriptionText {
    color: #ffffff;
    font-family: Montserrat, sans-serif;
    font-size: min(6vw, 32px);
    font-weight: 300;
    margin-top: 2%;
}

/* Button in section */
.buttonContainer {
    display: inline-block;
    background: none;
    border: 2px solid #ffffff;
    border-radius: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
    margin: min(10%, 40px);
    margin-top: min(5%, 20px);
    text-align: center;
    transition: all 0.5s ease;
    text-decoration: none;
}

/* Apply to parent container */
.buttonContainer:hover {
    color: #333333;
    background-color: #ffffff;
}

/* Apply to children elements */
.buttonContainer:hover *{
    color: #333333;
    transition: all 0.5s ease;
}

/* Icon in button */
.buttonIcon {
    display: inline-block;
    color: #ffffff;
    height: min(40px, 10vw);
    width: min(40px, 10vw);
    vertical-align: middle;
    margin-right: max(0.8vw, 10px);
}
/* Don't need to change this, just here so svg size changes */
.buttonIcon svg {
    display: block;
    margin: 0 auto;
    height: 100%;
    width: 100%;
}

/* Text in button */
.buttonText {
    display: inline-block;
    color: #ffffff;
    font-size: min(5vw, 24px);
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    margin: 0;
}
