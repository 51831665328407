.tableContainer {
    background-color: #efefef;
    justify-content: center;
    text-align: center;
    padding-top: 3%;
    padding-bottom: 3%;
}

/* OFFICERS text at start of section */
.sectionHeader {
    color: #232323;
    font-family: Montserat, sans-serif;
    font-size: min(64px, 12vw);
    font-weight: 300;
}

/* Current semester text */
.sectionSubHeader {
    color: #767676;
    font-family: Montserat, sans-serif;
    font-size: 32px;
    font-weight: 300;
}

.tableObject {
    background-color: #ffffff;
    margin-top: 2%;
}

/* Column header */
.tableHeader {
    color: #000000;
    font-family: Montserrat, sans-serif;
    font-size: min(18.4px, 4vw);
    font-weight: 700;
    padding: 0;
}

/* Column text */
.tableBody {
    color: #232323;
    font-family: Montserrat, sans-serif;
    font-size: min(18.4px, 4vw);
    font-weight: 400;
}
.tableBody td {
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
}