.sectionCol {
    text-align: center;
}

.sectionText {
    color: #383737;
    font-family: Montserrat, sans-serif;
    font-size: min(4vw, 32px);
    font-weight: 400;
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.cardRow {
    justify-content: center;
}

.cardCol {
    text-align: center;
}