.sectionCol {
    justify-content: center;
    text-align: center;
    margin-top: 4%;
}

.sectionText {
    color: #383737;
    font-family: Montserrat, sans-serif;
    font-size: min(4vw, 32px);
    font-weight: 300;
    margin-bottom: 3%;
}

.image {
    margin-left: 2vw;
    margin-right: 2vw;
    margin-bottom: 10px;
    max-width: 100%;
}