.footerContainer {
    background-color: #2d3a47;
    justify-content: center;
    padding-top: 2%;
}

.footerRow {
    justify-content:space-around;
}

.footerCol {
    margin-left: 2%;
    margin-right: 2%;
    padding-bottom: 1%;
}

.footerCol p {
    display: block;
}

.horizontalLine {
    width: 85%;
    border: 1px solid #fff;
    opacity: 0.1;
    margin-left: 7.5%;
}

/* BROTHERS ONLY */
.colTitleLink {
    font-family: Montserrat, sans-serif;
    font-size: min(20px, 5vw);
    font-weight: 700;
    color: #ffffff !important;
    text-decoration: none;
    transition: 0.3s ease color;
}

.colTitleLink:hover {
    color: var(--peacockBlue) !important;
}

/* Strong text at top of column */
.colTitle {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #a8f4dd;
}

/* Bigger text that immediately preceeds small text such as contact titles */
.colHeader {
    color: #ffffff;
    margin-top: 16px;
    margin-bottom: 0px;
}

/* Keeps first header from double spacing */
.colTitle + .colHeader {
    margin-top: 0px;
}

/* Any small text in footer */
.colText {
    font-family: Montserrat, sans-serif;
    font-size: 18.4px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 0px;
}

/* The text for the copyright statement at the bottom */
.copyrightText {
    font-family: Montserrat, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 0px;
}

/* The text for the address at the bottom */
.addressText {
    padding-bottom: 0 !important;
}

/* Text that links to another page */
.textLink {
    display: block;
    text-decoration: none;
}

.textLink:hover {
    color: #b3b3b3;
}

/* Instagram and facebook icons */
.socialIcon {
    text-align: right;
}
.socialIcon svg{
    font-size: 35px;
    color: white;
    margin-left: 5%;
    margin-right: 5%;
}

.socialIcon:hover svg {
    color: var(--peacockBlue);
}