.timelineItemContainer {
    align-self: flex-start;
    width: 50%;
    position: relative;
    padding-right: 30px;
    margin: 10px 0;
}

.timelineItemContainer:nth-child(odd) {
    align-self: flex-end;
    padding-right: 0px;
    padding-left: 30px;
}

.timelineItemContent {
    padding: 20px;
    background-color: #CCCCCC;
}

.contentTitle {
    color: #2a3a48;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
}

.contentTitle:hover {
    color: #2a3a48;
}

.timelineItemContent time {
    padding-left: 10px;
}

.contentDescription {
    color: #232323;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding-top: 20px;
}

.timelineIcon {
    --size: 40px;
    background-color: #CCCCCC;
    color: #CCCCCC;
    z-index: 100;
    border-radius: 50%;
    position: absolute;
    justify-content: center;
    text-align: center;
    align-items: center;
    top: calc(50% - calc(var(--size) / 2));
    right: calc(var(--size) / -2);
    width: var(--size);
    height: var(--size);
}

.timelineIcon * {
    width: var(--size);
    height: var(--size);
}

.timelineItemContainer:nth-child(odd) .timelineIcon {
    left: calc(var(--size) / -2)
}

@media only screen and (max-width: 900px) {
    .timelineItemContainer,
    .timelineItemContainer:nth-child(odd) {
        align-self: flex-start;
        width: 80%;
        padding-left: 0;
        padding-right: 30px;
        margin-left: 15%;
        margin-bottom: 5%;
    }

    .timelineIcon,
    .timelineItemContainer:nth-child(odd) .timelineIcon {
        --size: 5vw;
        right: 0;
        left: -9.5%;
    }
}