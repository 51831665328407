/* Entire card object */
.cardContainer {
    margin: 16px;
    padding: 0px;
    border: none;
}

/* Brother attribute name ie Hometown, Major, etc */
.infoHeader {
    color: #767676;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 700;
    display: inline;
}

/* Brother attribute value ie Memphis, TN, 6-3, etc */
/* .infoDescription { */
.infoDescription {
    color: #767676;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0px;
}

.positionFlair {
    background-color: #1ed7c0;
    color: #ffffff;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    text-align: center;
    width: 100%;
    font-weight: 700;
    display: inline-block;
    /* position: absolute;
    bottom: 0; */
}
.positionFlair * {
    margin: 0%;
    padding: 2%;
}

/* img {
    width: 100%;
    height: 300px;
    object-fit: cover;
} */