.sectionRow {
    background-color: #2d3a47;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
}

.sectionCol {
    text-align: center;
}

.sectionText {
    color: #ffffff;
    font-family: Montserrat, sans-serif;
    font-size: 18.4px;
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 20px;
}

.horizontalLine {
    width: 10%;
    border: 2px solid #a8f4dd;
    opacity: 1;
    margin-left: 45%;
}