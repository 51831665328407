.flippyContainer {
    height: 300px;
    width: 330px;
    margin-bottom: 50px;
}

.flippyCardFront {
    padding: 0 !important;
}

.flippyImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.flippyCardBack {
    background-color: #2d3a47 !important;
}

.cardTitle {
    color: #ffffff;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 700;
}

.cardDescription {
    color: #ffffff;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding-left: 10%;
    padding-right: 10%;
}