.container {
    background-color: #efefef;
}
.sectionRow {
    background-color: #efefef;
    align-items: center;
}

.sectionCol {
    text-align: center;
}

.sectionText {
    color: #000000;
    font-family: Montserrat, sans-serif;
    font-size: min(4vw, 32px);
    font-weight: 500;
}

.linkText {
    color: #000000;
    text-decoration: none;
    font-size: min(4vw, 32px);
    font-weight: 700;
}

.linkText:hover {
    color: #b3b3b3;
    text-decoration: none;
    font-size: min(4vw, 32px);
    font-weight: 700;
}