.rowContainer {
    justify-content: center;
    background-color: #efefef;
}

.classYearTitle {
    width: 100%;
    font-family: Montserrat, sans-serif;
    color: #2e2e2e;
    font-size: 64px;
    font-weight: 700;
    text-align: center;
}