.titleText {
    color: #FFFFFF;
    font-size: min(3.5vw, 22px);
    font-weight: 700;
    padding-top: 5px;
    padding-bottom: 5px;
    overflow-wrap: break-word;
}

.subtitleText {
    color: #FFFFFF;
    font-size: min(3.1vw, 20px);
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
}

.mainText {
    color: #FFFFFF;
    font-size: min(3.1vw, 20px);
    font-weight: 200;
    padding-top: 5px;
    padding-bottom: 5px;
    overflow-wrap: break-word;
}

.container {
    background-color: #2d3a47;
    /* width: 48%; */
}

.horizontalLine {
    width: 90%;
    border: 2px solid #a8f4dd;
    opacity: 1;
    margin-left: 5%;
    margin-top: 0;
    margin-bottom: 5px;
}

.profilePicture {
    /* Force the image to be square */
    width: min(140px, 60%);
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    padding: 0;
}