@media (min-width: 0px) {
    .videoPlayer {
        --width: 100vw;;
        width: var(--width);
        height: calc(0.5625 * var(--width));
        display: block;
        margin: auto;
    }
}

@media (min-width: 900px) {
    .videoPlayer {
        --width: 70vw;
        width: var(--width);
        height: calc(0.5625 * var(--width));
        display: block;
        margin: auto;
    }
}