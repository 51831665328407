.timelineContainer {
    display: flex;
    flex-direction: column;
    position: relative;
}

.timelineContainer::after {
    content: '';
    position: absolute;
    left: calc(50% - 1px);
    width: 2px;
    height: 100%;
    background-color: #CCCCCC;
}

@media only screen and (max-width: 900px) {
    .timelineContainer::after {
        left: 10%;
    }
}