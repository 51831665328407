.formContainer {
    background-color: #efefef;
}

.form {
    background-color: #2d3a47;
    justify-content: center;
}

.crest {
    width: max(22%, 9rem);
}

.linkTitle {
    font-family: Montserrat, sans-serif;
    font-size: min(32px, 5vw);
    font-weight: 700;
    color: #ffffff;
}

.input {
    border: none;
    border-radius: 0;
}

.input:focus {
    outline: none;
    box-shadow: none;
}

.inputLinks {
    font-family: Montserrat, sans-serif;
}

.submit {
    border: none;
    border-radius: 0;
    background-color: white;
    color: black;
    width: 8rem;
    transition: background-color 0.3s ease;
    font-family: Montserrat, sans-serif;
}

.submit:hover {
    background-color: white;
    color: black;
    background-color: #a8f4dd;
}

.links {
    color: white;
    font-family: Montserrat, sans-serif;
    text-decoration: none;
    cursor: pointer;
}

.links:hover {
    color: #a8f4dd;
}

.inputName {
    width: 49.5%;
}

.error {
    font-family: Montserrat, sans-serif;
    color: red;
    font-weight: 700;
}