:root {
    --evenDarkerPeacockBlue: #23b187;
    --backgroundColor: #afefdc;
}

#resultsBox::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	border-radius: 10px;
	background-color: white;
}

#resultsBox::-webkit-scrollbar
{
	width: 12px;
	background-color: white;
    border-radius: 5px;
}
#resultsBox::-webkit-scrollbar-thumb
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
	background-color: var(--evenDarkerPeacockBlue);
    border-radius: 3px;
}

#resultsBox {
    width: 275px;
    /* max-height: 50vh; */
    position: absolute;
    overflow-y: scroll; 
    overflow-x: hidden; 
    cursor: default;
    /* color: #23b187 */
    margin-top: -2px;
    z-index: 2;
}

#controls {
    padding-bottom: 10px;
    background-color: var(--backgroundColor);
}
.controlssection {
    padding-top: 10px;
    padding-left: 18px;
    display: inline-block;
}

.textinp {
    border: 2px solid var(--evenDarkerPeacockBlue);
    border-radius: 5px;
    transition: 0.2s ease;
    width: 275px;
    padding-left: 7px;
    padding-right: 7px;
    font-family: sf-med;
}
.textinp:focus {
    outline: 1px solid var(--peacockBlue) ;
}
.textinp::placeholder {
    color: var(--evenDarkerPeacockBlue);
}

.expcolbtn {
    border-radius: 5px;
    border: 2px solid var(--evenDarkerPeacockBlue);
    background-color: var(--evenDarkerPeacockBlue);
    color: white;
    transition: 0.2s ease;
    min-width: 100px;
    font-family: sf-med;
}
.expcolbtn:hover {
    background-color: var(--darkerPeacockBlue);
    border-color: var(--darkerPeacockBlue);
}

#expandBtn {
    /* margin-left: 22px; */
}
#collapseBtn {
    margin-left: 7px;
}

#treeSvg {
    flex-grow: 1;
}
#treeWrapper {
    display: flex;
    flex-flow: column;
    height: 100vh;
}

#pbeTitle {
    font-size: 100%;
    color: var(--evenDarkerPeacockBlue);
    padding-left: 1.75%;
}

@font-face {
    font-family: "sf-reg";
    src: url('./fonts//SF-Pro-Display-Regular.otf');
}

@font-face {
    font-family: "sf-med";
    src: url('./fonts/SF-Pro-Display-Medium.otf');
}
/* 
* {
    font-family: sf-reg;
} */


/* HOVER DROPDOWN (HELP + STATS) CSS */
/* Dropdown Button */
.dropbtn {
    margin-left: 0 !important;
    border-radius: 0px !important;
    border: 2px solid var(--darkerPeacockBlue);
    background-color: var(--darkerPeacockBlue);;
    color: white;
    transition: none !important;
    width: 150px;
    font-family: sf-med;
  }

  .dropbtn:hover {
    background-color: var(--evenDarkerPeacockBlue);
    border-color: var(--evenDarkerPeacockBlue);
    color: white;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    display: inline-block;
    /* margin-left: 1.75%; */
  }

  #statsdrop {
    /* margin-left: 7px; */
    border-left: 1px solid var(--backgroundColor);
    z-index: 3;
  }

  #statsdrop .dropdown-content {
    margin-left: -151px;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    padding: 10px 0;
    margin-top: -0.1px;
    display: none;
    position: absolute;
    background-color: var(--evenDarkerPeacockBlue);
    min-width: 301px;
    z-index: 1;
    /* border-radius: 5px; */
    /* border: 2px solid var(--evenDarkerPeacockBlue); */
  }
  
  /* Links inside the dropdown */
  .dropdown-content span {
    vertical-align: baseline;
  }
  .dropdown-content .category {
    color: white;
    padding: 6px 15px;
    text-decoration: none;
    display: block;
    font-family: sf-reg;
  }
  .dropdown-content .subcat {
    color: white;
    padding: 1px 25px;
    text-decoration: none;
    display: block;
    font-size: 80%;
    font-family: sf-reg;
  }
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  .dropdown:hover .dropbtn {
    background-color: var(--evenDarkerPeacockBlue);
    border-color: var(--evenDarkerPeacockBlue);
    color: white;
}
