Navbar {
    background-color: red !important;
}

/* Triangle logo in nav bar */
.navLogo {
    height: 60px;
}

/* PHI BETA EPSILON */
.navBrand {
    font-family: Montserrat, sans-serif;
    font-size: min(32px, 5vw);
    font-weight: 700;
    color: #ffffff !important;
    margin-right: 2%;
}
@media only screen and (max-width: 400px) {
    .navBrand {
        font-size: 20px;
    }
}

.navBrand:hover {
    color: #b3b3b3 !important;
}

/* Div around triangle and text */
.logoBrandDiv {
    display: flex;
    justify-content: center;
    margin-left: 2%;
    margin-right: 2%;
    align-items: center;
}

/* Container for lines making up hamburger menu */
.navToggleDiv {
    border: none;
    box-shadow: none;
}

.navToggleDiv:focus {
    border: none;
    box-shadow: none;
}

/* Lines making up hamburger menu when screen < lg (992px) */
.navToggleLine {
    color: #ffffff;
    height: 3px;
    width: 30px;
    background-color: #ffffff;
    transition: all 0.3s ease;
}

/* Orient lines when in hamburger configuration */
.navToggleLine.line1 {
    margin-bottom: 6px;
}
.navToggleLine.line2 {
    margin-bottom: 0px;
    position: absolute;
}
.navToggleLine.line3 {
    margin-bottom: 6px;
}
.navToggleLine.line4 {
    margin-bottom: 0px;
}

/* Animate lines when hamburger button clicked, resulting in X icon */
.toggle .navToggleLine.line1 {
    transform: scale(0, 0) translate(0px, 6px);
    opacity: 0;
}
.toggle .line2 {
    transform: rotate(45deg);
}
.toggle .line3 {
    transform: rotate(-45deg);
}
.toggle .line4 {
    transform: scale(0, 0) translate(0px, -6px);
    opacity: 0;
}

@keyframes navToggleAnimationLine1 {
    from {
        transform: scale(1, 1);
        transform: translate(0px, 0px);
    }
    to {
        transform: scale(0, 0);
        transform: translate(0px, 6px);
    }
}

/* Div around other links in navbar ie brothers, house, etc */
.navLinksDiv {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 8%;
    line-height: 18.4px;
}

/* Actual nav links ie brothers, house, etc */
.navLink {
    color: #ffffff !important;
    font-family: Montserrat, sans-serif;
    font-size: 18.4px;
    font-weight: 600;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 5px;
}

.navLink:hover {
    color: #B9F2DE !important;
}

.navLink svg {
    font-size: 20px;
}